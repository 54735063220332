<template>
	<!-- :max-height="maxHeight" -->
  <el-table
    border
    :data="emplData"
    stripe
    style="width: 100%;"
    :max-height="maxHeight"
    v-loading="isLoading"
    element-loading-text="加载中"
    :row-style="{ height: '150px' }"
    element-loading-background="rgba(0, 0, 0, 0)"
    @cell-click="openOrderDetail">
    <!-- 工作人员列 -->
    <el-table-column label="工作人员" align="center" fixed="left"  width="150">
      <template slot-scope="scope">
        <div>
          <!-- 头像 -->
          <div class="block">
            <el-avatar :src="scope.row.Picture"></el-avatar>
          </div>
          <!-- 名称 -->
          <div>{{ scope.row.EmplName }}</div>
          <!-- 职位 -->
          <div>{{ scope.row.LevelName }}</div>
        </div>
      </template>
    </el-table-column>
    <!-- 工作时间列 -->
    <el-table-column
      v-for="(item, index) in timeArrangementData"
      :key="index"
      :label="item.Begin + '-' + item.End"
      align="center"
      width="180"
      :column-key="'key' + index">
      <template slot-scope="scope">
        <div style="width:180px;height:150px"
        :class=" orderData[scope.row.ReserveTimes[index].StatusDataId] ? `bg-them-${orderData[scope.row.ReserveTimes[index].StatusDataId].Status}` : '' ">
          <!-- 时间 -->
          <div class="app-time">{{ item.Begin }}-{{ item.End }}</div>
          <!-- 判定这一行 每一列 是否有预约 2021-11-16 -->
          <div class="app-content"  v-if="scope.row.ReserveTimes[index].StatusDataId != 0 ">
            <template v-if="orderData[scope.row.ReserveTimes[index].StatusDataId].Status == 1 || orderData[scope.row.ReserveTimes[index].StatusDataId].Status == 3 || orderData[scope.row.ReserveTimes[index].StatusDataId].Status == 5">
               <!-- 预约人姓名 -->
              <div class="one-text">
                {{
                orderData[scope.row.ReserveTimes[index].StatusDataId]
                ? orderData[
                scope.row.ReserveTimes[index].StatusDataId
                ].ContactName
                : ""
                }}
              </div>
              <!-- 预约人电话 -->
              <div class="one-text">
                {{
                orderData[scope.row.ReserveTimes[index].StatusDataId]
                ? orderData[
                scope.row.ReserveTimes[index].StatusDataId
                ].ContactPhone
                : ""
                }}
              </div>
              <!-- 预约时间 -->
              <div class="two-text">
                {{
                orderData[scope.row.ReserveTimes[index].StatusDataId]
                ? '回复：'+dateFormat('HH:MM',new Date(orderData[scope.row.ReserveTimes[index].StatusDataId].UpdateTime*1000))
                : ""
                }}
              </div>
              <!-- 预约人备注 -->
              <div class="two-text">
                {{
                orderData[scope.row.ReserveTimes[index].StatusDataId] && orderData[scope.row.ReserveTimes[index].StatusDataId].Remark.length != 0 ? orderData[
                scope.row.ReserveTimes[index].StatusDataId
                ].Remark
                : "无备注"
                }}
              </div>
            </template>
          </div>

          <!-- 状态标识  2021-11-16 v-if="scope.row.ReserveTimes[index].StatusDataId != 0"-->
          <div class="icon-status" v-if="scope.row.ReserveTimes[index].StatusDataId != 0 && (orderData[scope.row.ReserveTimes[index].StatusDataId].Status == 1 || orderData[scope.row.ReserveTimes[index].StatusDataId].Status == 3 || orderData[scope.row.ReserveTimes[index].StatusDataId].Status == 5)">
            {{
            orderData[scope.row.ReserveTimes[index].StatusDataId]
            ? orderStatus[
            orderData[
            scope.row.ReserveTimes[index].StatusDataId
            ].Status
            ].smText
            : "err"
            }}
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { orderStatus } from '../../../../constants/const';
export default {
  name: "reservation-table",
  props: {
    emplData: { 
			type: Array, 
			default: () => [] 
		},
    timeArrangementData: { 
			type: Array, 
			default: ()=> [] 
		},
    orderData: { 
			type: Object, 
			default: () =>  { return {} } 
		},
    openOrderDetail: { 
			type: Function, 
			default: () => {} 
		},
    isLoading:{
			type : Boolean , 
			default : false
		}
  },
  data() {
    return {
      // isLoading: false, // 是否显示表格loading
      maxHeight: "", // 表格最大高度
      orderStatus,
    };
  },
  created() {
    // 设置table高度
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.maxHeight = h - 60 - 40 - 60;
  },
  methods: {
    dateFormat(fmt, date){
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        "F+": date.getMilliseconds().toString()
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      let ret
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
      };
      return fmt;
    },
  },
  
};
</script>

<style lang="less" scoped>
::v-deep .el-avatar{
  width: 80px !important;
  height: 80px !important;
}
</style>