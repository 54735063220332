<template>
  <!-- 创建预约弹窗 -->
  <el-dialog
    title="创建预约"
    :visible.sync="isShow"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="dialogClose"
  >
    <!-- 查询会员页面 -->
    <el-form
      ref="selectForm"
      status-icon
      :rules="rules"
      :model="selectForm"
      label-width="80px"
      label-position="left"
      v-loading="isLoading"
      element-loading-text="加载中"
    >
      <!-- 手机号码 -->
      <el-form-item label="手机号码" prop="tel">
        <el-input
          v-model="selectForm.tel"
          placeholder="请输入手机号码"
        ></el-input>
      </el-form-item>
      <!-- 姓名 -->
      <el-form-item label="姓名" prop="name">
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <el-input
              v-model="selectForm.name"
              placeholder="请填写预约客户姓名"
            ></el-input>
          </el-col>
          <el-col :span="11">
            <el-select v-model="selectForm.gender">
              <el-option
                v-for="(item, index) in genderData"
                :key="index"
                :label="item.text"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- 项目分类 -->
      <el-form-item label="项目分类" prop="itemClass">
        <el-select
          v-model="selectForm.itemClass"
          placeholder="请选择"
          style="width: 100%"
          @change="getItemSelectorFun"
        >
          <el-option
            v-for="item in itemClassData"
            :key="item.ClassId"
            :label="item.ClassName"
            :value="item.ClassId"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 项目 -->
      <el-form-item label="项目" prop="item">
        <el-select
          v-model="selectForm.item"
          placeholder="请选择"
          style="width: 100%"
          @change="getEmplSelectorFun"
        >
          <el-option
            v-for="item in itemData"
            :key="item.ItemGuid"
            :label="item.ItemName"
            :value="item.ItemGuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 服务员工 -->
      <el-form-item label="服务员工" prop="empl">
        <el-select
          v-model="selectForm.empl"
          placeholder="请选择"
          style="width: 100%"
          @change="chooseEmpl"
        >
          <el-option
            v-for="item in emplOptionData"
            :key="item.EmplGuid"
            :label="item.EmplName"
            :value="item.EmplGuid"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 预约时间 -->
      <el-form-item label="预约时间" size="normal">
        <el-col :span="12" :offset="0">
          <el-form-item size="normal" prop="appointmentDate">
            <el-date-picker
              v-model="selectForm.appointmentDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              @change="getTimeSelectorFun"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item size="normal" prop="appointmentBeginTime">
            <el-select
              v-model="selectForm.appointmentBeginTime"
              placeholder="请选择"
              style="width: 100%"
              @change="getEndTime"
            >
              <el-option
                v-for="(item, index) in timeRange"
                :key="index"
                :label="item.Begin"
                :value="item.Begin"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form-item>
      <!-- 备注 -->
      <el-form-item label="备注" prop="note">
        <el-input v-model="selectForm.note" placeholder="请输入备注"></el-input>
      </el-form-item>
      <!-- 提交按钮 -->
      <el-button
        ref="submitButton"
        type="primary"
        @click="onSubmit"
        size="default"
        >创建预约</el-button
      >
    </el-form>
  </el-dialog>
</template>

<script>
import {
  findMember,
  getItemClassSelector,
  getItemSelector,
  getEmplSelector,
  getTimeSelector,
  saveOrder,
} from "@/api/reservation";
import { isTodayFun, addZero, judgeEndTime } from "@/utils/utils";
import { genderData } from "@/constants/const";
export default {
  name: "reservation-dialog-create",
  props: {},
  data() {
    // 输入手机号后的查询
    var checkTel = (rule, value, callback) => {
      if (!value || isNaN(value) || value.length != 11) {
        callback(Error("请输入正确的手机号"));
      } else {
        callback(this.findMemberFun(value));
      }
    };
    return {
      rules: {
        // 表单验证规则
        tel: [{ validator: checkTel, trigger: "blur" }],
        name: [{ required: true, message: "请输入预约姓名", trigger: "blur" }],
        itemClass: [
          { required: true, message: "请选择项目分类", trigger: "blur" },
        ],
        item: [{ required: true, message: "请选择项目", trigger: "blur" }],
        empl: [{ required: true, message: "请选择服务员工", trigger: "blur" }],
        appointmentDate: [
          { required: true, message: "请选择预约日期", trigger: "blur" },
        ],
        appointmentBeginTime: [
          { required: true, message: "请选择预约时间", trigger: "blur" },
        ],
      },
      selectForm: {
        //创建预约表单数据
        memberGuid: "", // 会员guiid
        tel: "", // 查询的电话
        name: "", //查询的姓名
        gender: 1, //用户性别
        itemClass: "", //项目分类
        item: "", //项目
        itemDuration: "", //项目耗时 min
        empl: "", //服务员
        appointmentDate: "", //预约日期
        appointmentBeginTime: "", //预约时间
        appointmentEndTime: "", // 预约结束时间
        note: "", //备注
      },
      // 性别选项
      genderData,
      itemClassData: [], // 项目分类选项
      itemData: [], // 项目选项
      emplOptionData: [], // 服务员工选项
      timeRange: [], // 员工可以选择时间范围
      isShow: false, //是否显示预约弹窗
      isLoading: false, // 是否显示新建预约加载
    };
  },
  created() {
    // 默认选中第一个性别
    this.selectForm.gender = this.genderData[0].value;
    //this.$root.$store.state.token
    if (!this.$store.state.token) {
      return;
    }
    // 获取项目分类
    this.getItemClassSelectorFun();
  },
  methods: {
    // 预约 => 创建预约页面关闭
    dialogClose() {
      this.selectForm = {
        //创建预约表单数据
        tel: "", // 查询的电话
        name: "", //查询的姓名
        gender: 1, //用户性别
        itemClass: "", //项目分类
        item: "", //项目
        itemDuration: "", //项目耗时
        empl: "", //服务员
        appointmentDate: "", //预约日期
        appointmentBeginTime: "", //预约时间
        appointmentEndTime: "", // 预约结束时间
        note: "", //备注
      };
      this.timeRange = [];
      this.isLoading = false;
    },
    // 预约 => 获取项目分类选择列表
    getItemClassSelectorFun() {
      let that = this;
      getItemClassSelector({}).then((res) => {
        console.log(res);
        if (res.data && res.data.length > 0) {
          that.itemClassData = res.data;
        }
      });
    },
    // 选择项目分类 => 获取项目选择列表
    getItemSelectorFun(class_id) {
      let that = this;
      this.itemData = [];
      this.emplOptionData = [];
      this.selectForm.item = null;
      this.selectForm.empl = null;
      this.selectForm.appointmentDate = null;
      this.selectForm.appointmentBeginTime = null;
      this.selectForm.appointmentEndTime = null;
      getItemSelector({ class_id })
        .then((res) => {
          console.log(res);
          if (res.data != null || res.data.length > 0) {
            that.itemData = res.data;
          }
        })
        .catch((err) => {
          that.$message("获取数据失败");
        });
    },
    // 选择项目 => 获取员工选择列表
    getEmplSelectorFun(item_guid) {
      let that = this;
      // 获取项目耗时
      for (let i = this.itemData.length - 1; i >= 0; i--) {
        if (this.itemData[i].ItemGuid == item_guid) {
          this.selectForm.itemDuration = this.itemData[i].ItemDuration;
          break;
        }
      }
      that.emplOptionData = [];
      that.selectForm.empl = null;
      getEmplSelector({ item_guid })
        .then((res) => {
          if (res.data != null || res.data.length > 0) {
            that.emplOptionData = res.data;
            that.$forceUpdate();
            // console.log("this => ", that.emplOptionData);
          }
        })
        .catch((err) => {
          that.$message("获取数据失败");
        });
    },
    // 预约 => 选择日期事件 => 获取可以选择约的时间
    getTimeSelectorFun(reserve_date) {
      let that = this;
      if (!this.selectForm.empl) {
        this.$message("请先选择服务员工,才能获取预约时间数据");
        this.selectForm.appointmentDate = null;
        this.selectForm.appointmentBeginTime = null;
        this.selectForm.appointmentEndTime = null;
        return;
      }
      // 判断是否是当天 0 是当日 -1 是之前的日期 1 是未来
      let isToday = isTodayFun(reserve_date);
      if (isToday == -1) {
        this.$message("请勿选择过期的时间");
        this.selectForm.appointmentDate = null;
        this.selectForm.appointmentBeginTime = null;
        this.selectForm.appointmentEndTime = null;
        return;
      }
      getTimeSelector({ reserve_date, empl_guid: this.selectForm.empl })
        .then((res) => {
          if (res.data.length < 1 || res.errcode != 0) {
            that.timeRange = [];
            return;
          }
          if (isToday == 0) {
            // 当天
            let day = `2000-01-01 `;
            let timestampNow = new Date(
              `${day} ${new Date().getHours()}:${new Date().getMinutes()}`
            ).getTime();
            // 时间范围数据
            let timeRange = [];
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
              let timestampStar = (day + data[i].Begin).toString();
              timestampStar = new Date(timestampStar).getTime();
              if (timestampStar > timestampNow) {
                timeRange.push(data[i]);
              }
            }
            that.timeRange = timeRange;
          } else if (isToday == 1) {
            that.timeRange = res.data;
          }
        })
        .catch((err) => {
          that.$message("获取数据失败");
        });
    },
    // 预约 => 选择完日期和时间 根据项目时间设置结束时间
    getEndTime() {
      // 没有选择预约时间、项目 不执行
      if (
        !this.selectForm.appointmentBeginTime ||
        this.selectForm.itemDuration == null
      ) {
        return;
      }
      // 项目结束时间的时间戳 项目耗时为0的时候 默认15分钟
      let endTimeStamp =
        new Date(
          `2020-01-01 ${this.selectForm.appointmentBeginTime}`
        ).getTime() +
        (this.selectForm.itemDuration == 0
          ? 15
          : this.selectForm.itemDuration) *
          60 *
          1000;
      let endTime = new Date(endTimeStamp);
      // 门店关闭时间
      let closeTimeStamp = new Date(
        `2020-01-01 ${this.timeRange[this.timeRange.length - 1].End}`
      ).getTime();
      if (endTimeStamp > closeTimeStamp) {
        this.$message("项目时间已经超过休息时间或营业时间,请重新选择");
        runBack(this);
        return;
      }
      let appointmentEndTime = `${addZero(endTime.getHours())}:${addZero(
        endTime.getMinutes()
      )}`;

      let x = judgeEndTime(
        this.selectForm.appointmentBeginTime,
        appointmentEndTime,
        this.timeRange
      );
      if (x == true) {
        // 赋值
        this.selectForm.appointmentEndTime = appointmentEndTime;
      } else {
        runBack();
      }
      function runBack(that) {
        that.selectForm.appointmentBeginTime = "";
        that.selectForm.appointmentEndTime = "";
      }
    },
    // 预约 => 表单提交
    onSubmit() {
      // console.log("this => ", this.selectForm);
      let that = this;
      this.$refs["selectForm"].validate(async (valid) => {
        if (valid) {
          // 开始提交
          // console.log("提交");
          let param = {
            OrderId: 0,
            ContactPhone: this.selectForm.tel,
            ContactName: this.selectForm.name,
            ContactSex: this.selectForm.gender,
            MemberGuid: this.selectForm.memberGuid
              ? this.selectForm.memberGuid
              : "",
            ItemGuid: this.selectForm.item,
            EmplGuid: this.selectForm.empl,
            ReserveDate: this.selectForm.appointmentDate,
            BeginTime: this.selectForm.appointmentBeginTime,
            EndTime: this.selectForm.appointmentEndTime,
            Remark: this.selectForm.note,
          };
          this.$refs.submitButton.disabled = await true;
          that.isLoading = await true;
          await saveOrder(param)
            .then(async (res) => {
              if (res.errcode == (await 0)) {
                await that.$message({
                  duration: 1000,
                  message: "预约成功",
                  onClose: async () => {
                    that.isShow = await false;
                    await that.dialogClose();
                    // that.$router.go(0);
                    await that.$parent.getDailyDataFun();
                  },
                });
              } else {
                that.isLoading = await false;
                await that.$message(res.errmsg);
              }
            })
            .catch(async (err) => {
              that.isLoading = await false;
              await that.$message("提交失败");
            });
        } else {
          return false;
        }
        this.$refs.submitButton.disabled = await false;
      });
    },
    // 选择服务员工 => 每次选择清空预约时间数据
    chooseEmpl() {
      this.selectForm.appointmentDate = null;
      this.selectForm.appointmentBeginTime = null;
      this.appointmentEndTime = null;
    },
    // 预约 => 根据正确的电话号码获取会员数据
    findMemberFun(phone) {
      let that = this;
      findMember({ phone })
        .then((res) => {
          if (res.errcode == 0 && res.data != null) {
            that.selectForm.memberGuid = res.data.MemberGuid;
            that.selectForm.name = res.data.MemberName;
            that.selectForm.gender = res.data.MemberSex;
          }
        })
        .catch((err) => {
          this.$message("获取数据失败");
        });
    },
  },
};
</script>

<style>
</style>