// 订单状态
export const orderStatus = [
    "无",
    {
        status: 1,
        text: "待回复",
        smText: "待回复"
    },
    {
        status: 2,
        text: "已取消",
        smText: "已取消"
    },
    {
        status: 3,
        text: "已接受",
        smText: "已接受"
    },
    {
        status: 4,
        text: "已拒绝",
        smText: "已拒绝"
    },
    {
        status: 5,
        text: "已完成",
        smText: "已完成"
    },
    {
        status: 6,
        text: "已删除",
        smText: "已删除"
    },
]
// 性别选项
export const genderData = [{
    text: "男",
    value: 1
}, {
    text: "女",
    value: 0
}, ]