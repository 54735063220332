<template>
  <div>
    <!-- 处理弹窗 -->
    <el-dialog
      title="预约订单详情"
      :visible.sync="isShow"
      custom-class="dialog-deal-box"
      v-if="orderDetail"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div
        class="el-dialog-div"
        v-loading="isLoading"
        element-loading-text="加载中"
      >
        <!-- 显示的信息 -->
        <el-container>
          <el-main height>
            <!-- Main content -->
            <div>
              <!-- 客户信息 -->
              <div>
                <p class="text-left">客户信息</p>
                <ul class="dialog-deal-ul">
                  <!-- 客户信息 -->
                  <li>
                    <b>客户信息:</b>
                    <span class="padding-left-sm">{{
                      orderDetail.ContactName
                    }}</span>
                  </li>
                  <!-- 客服电话 -->
                  <li>
                    <b>客服电话:</b>
                    <span class="padding-left-sm">{{
                      orderDetail.ContactPhone
                    }}</span>
                  </li>
                  <!-- 预约员工 -->
                  <li>
                    <b>预约员工:</b>
                    <span class="padding-left-sm">{{
                      orderDetail.EmplName
                    }}</span>
                  </li>
                  <!-- 预约项目 -->
                  <li>
                    <b>预约项目:</b>
                    <span class="padding-left-sm">{{
                      orderDetail.ItemName
                    }}</span>
                  </li>
                  <!-- 预约时间 -->
                  <li>
                    <el-row class="flex align-center">
                      <el-col :span="3" :offset="0">
                        <b>预约时间:</b>
                      </el-col>
                      <el-col :span="21" :offset="0">
                        <span class="padding-left-sm" v-show="!isEdit">
                          {{
                            `${util.formatDate(
                              orderDetail.BeginTime
                            )}-${util.formatDate(orderDetail.EndTime, 1)}`
                          }}
                        </span>
                        <div v-show="isEdit">
                          <el-col :span="12" :offset="0">
                            <el-date-picker
                              v-model="orderDetailEdit.date"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="选择日期"
                              style="width: 100%"
                            ></el-date-picker>
                          </el-col>
                          <el-col :span="12" :offset="0">
                            <el-select
                              v-model="orderDetailEdit.beginTime"
                              placeholder="请选择"
                              style="width: 100%"
                              @change="getEditEndTime"
                            >
                              <el-option
                                v-for="(
                                  item, index
                                ) in orderDetailEdit.timeRange"
                                :key="index"
                                :label="item.Begin"
                                :value="item.Begin"
                              ></el-option>
                            </el-select>
                          </el-col>
                        </div>
                      </el-col>
                    </el-row>
                  </li>
                  <!-- 客服回复 -->
                  <li>
                    <el-row class="flex align-center">
                      <el-col :span="3" :offset="0">
                        <b>客服回复:</b>
                      </el-col>
                      <el-col :span="21" :offset="0">
                        <span class="padding-left-sm" v-show="!isEdit">{{
                          orderDetail.Remark ? orderDetail.Remark : "无备注"
                        }}</span>
                        <div v-show="isEdit">
                          <el-input
                            v-model="orderDetailEdit.remark"
                            placeholder
                            size="normal"
                          ></el-input>
                        </div>
                      </el-col>
                    </el-row>
                  </li>
                  <!-- 填写时间 -->
                  <li>
                    <b>填写时间:</b>
                    <span class="padding-left-sm">{{
                      util.formatDate(orderDetail.CreateTime)
                    }}</span>
                  </li>
                </ul>
              </div>
              <!-- 预约状态 -->
              <div>
                <p class="text-left">预约状态</p>
                <el-select
                  v-model="orderDetailStatus"
                  disabled
                  placeholder="请选择"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in orderDetailStatusData"
                    :key="index"
                    :label="item.text"
                    :value="item.status"
                  ></el-option>
                </el-select>
              </div>
              <!-- 客服备注 -->
              <div>
                <p class="text-left">客服备注</p>
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="请输入内容"
                  style="width: 100%"
                  v-model="refuseReason"
                  :disabled="orderDetailStatus && orderDetailStatus != 1"
                ></el-input>
              </div>
            </div>
          </el-main>

          <el-footer height>
            <!-- Footer content -->
            <!-- 按钮 -->
            <el-row
              :gutter="20"
              v-if="orderDetailStatus && orderDetailStatus != 4"
            >
              <el-col
                :span="6"
                v-if="orderDetailStatus && orderDetailStatus == 1"
              >
                <el-button
                  ref="submitButton3"
                  type="success"
                  size="default"
                  style="width: 100%"
                  @click="changeOrderStatusFun(3)"
                  :disabled="isEdit"
                  >确认预约</el-button
                >
              </el-col>
              <el-col
                :span="6"
                v-if="orderDetailStatus && orderDetailStatus == 1"
              >
                <el-button
                  ref="submitButton4"
                  type="warning"
                  size="default"
                  style="width: 100%"
                  @click="changeOrderStatusFun(4)"
                  :disabled="isEdit"
                  >拒绝预约</el-button
                >
              </el-col>
              <el-col
                :span="6"
                v-if="orderDetailStatus && orderDetailStatus == 1"
              >
                <el-button
                  ref="submitButton0"
                  :type="!isEdit ? 'info' : 'primary'"
                  size="default"
                  style="width: 100%"
                  @click="changeOrderStatusFun(0)"
                  >{{ !isEdit ? "修改预约" : "确认修改" }}</el-button
                >
              </el-col>
              <el-col
                :span="16"
                v-if="orderDetailStatus && orderDetailStatus == 3"
              >
                <el-button
                  ref="submitButton5"
                  type="success"
                  size="default"
                  style="width: 100%"
                  @click="changeOrderStatusFun(5)"
                  >完成预约</el-button
                >
              </el-col>
              <el-col
                :span="orderDetailStatus == 3 ? 8 : 6"
                v-if="
                  orderDetailStatus &&
                  orderDetailStatus != 6 &&
                  orderDetailStatus != 5
                "
              >
                <el-button
                  ref="submitButton6"
                  type="danger"
                  size="default"
                  style="width: 100%"
                  @click="changeOrderStatusFun(6)"
                  :disabled="isEdit"
                  >删除预约</el-button
                >
              </el-col>
            </el-row>
          </el-footer>
        </el-container>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTimeSelector,
  saveOrder,
  changeOrderStatus,
} from "@/api/reservation";
import {
  addZero,
  getEndTime as getEndTimeFun,
  judgeEndTime,
} from "@/utils/utils";
export default {
  name: "reservation-dialog-deal",
  props: {
    orderDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    orderDetailStatus: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // 预约订单状态渲染列表
      orderDetailStatusData: [
        { status: 1, text: "待回复" },
        { status: 3, text: "已确认" },
        { status: 4, text: "已拒绝" },
        { status: 5, text: "已完成" },
        { status: 6, text: "已删除" },
      ],
      // 订单详情 需要修改的数据
      orderDetailEdit: {
        date: "", // 选择的日期
        beginTime: "", // 开始时间
        endTime: "", //结束时间
        timeRange: [], // 时间范围
        remark: "",
      },
      refuseReason: "", // 拒绝预约 原因填写
      isEdit: false, //是否编辑预约
      isShow: false, //是否显示处理弹窗
      isLoading: false, // 是否显示处理预约弹窗loading
    };
  },
  created() {},
  methods: {
    // 处理预约
    async changeOrderStatusFun(status) {
      let that = this;
      //   console.log("this.orderdetail => ", this.orderDetail);
      this.$refs["submitButton" + status.toString()].disabled = await true;
      // 处理修改预约
      if (status == (await 0) && this.isEdit == (await false)) {
        this.isEdit = await true;
        // 赋值input
        this.orderDetailEdit.remark = await this.orderDetail.Remark;
        // 预约日期
        let reserveDate = await new Date(this.orderDetail.BeginTime * 1000);
        this.orderDetailEdit.date =
          await `${reserveDate.getFullYear()}-${addZero(
            reserveDate.getMonth() + 1
          )}-${addZero(reserveDate.getDate())}`;
        // 预约时间
        this.orderDetailEdit.beginTime = await `${addZero(
          reserveDate.getHours()
        )}:${addZero(reserveDate.getMinutes())}`;
        // 获取相对的时间范围
        await getTimeSelector(this.orderDetailEdit.date).then(async (res) => {
          // console.log("编辑的时间范围 => ", res);
          if (res.errcode == (await 0) && (await res.data)) {
            that.orderDetailEdit.timeRange = await res.data;
            await that.getEditEndTime();
          }
        });
        return;
      }
      // 修改状态
      if (status != (await 0)) {
        let param = {
          order_id: this.orderDetail.Id,
          status,
        };
        if (status == (await 4)) {
          param.refuse_reason = this.refuseReason;
        }
        that.isLoading = await true;
        await changeOrderStatus(param)
          .then(async (res) => {
            if (res.errcode == (await 0)) {
              await that.$message({
                message: "修改成功",
                duration: 1000,
                onClose: async () => {
                  that.isShow = await false;
                  await that.$parent.getDailyDataFun();
                },
              });
            } else {
              that.isLoading = await false;
              await that.$message(res.errmsg);
            }
          })
          .catch(async (err) => {
            await that.$message("修改失败");
          });
      } else {
        that.isLoading = await true;
        // 修改订单详情
        let param = {
          OrderId: this.orderDetail.Id,
          ReserveDate: this.orderDetailEdit.date,
          BeginTime: this.orderDetailEdit.beginTime,
          EndTime: this.orderDetailEdit.endTime,
          Remark: this.orderDetailEdit.remark,
        };

        await saveOrder(param)
          .then(async (res) => {
            if (res.errcode == (await 0)) {
              await that.$message({
                type: "success",
                message: "修改成功",
                duration: 1000,
                async onClose() {
                  that.isShow = false;
                  that.isEdit = false;
                  await that.$parent.getDailyDataFun();
                },
              });
            } else {
              that.isLoading = false;
              await that.$message(res.errmsg);
            }
          })
          .catch(async (err) => {
            await that.$message(res.errmsg);
          });
      }
      this.$refs["submitButton" + status.toString()].disabled = await false;
    },

    // 处理编辑状态的endTime
    getEditEndTime() {
      // 计算项目相差时间
      let time = (this.orderDetail.EndTime - this.orderDetail.BeginTime) / 60;
      // 赋值
      if (!getEndTimeFun(this.orderDetailEdit.beginTime, time)) {
        this.$message("项目时间过长,请修改");
        runBack(this);
        return;
      }
      this.orderDetailEdit.endTime = getEndTimeFun(
        this.orderDetailEdit.beginTime,
        time
      );
      // 计算endtime 是否超过营业时间和休息时间
      let status = judgeEndTime(
        this.orderDetailEdit.beginTime,
        this.orderDetailEdit.endTime,
        this.orderDetailEdit.timeRange
      );
      if (status != true) {
        this.$message("项目时间已超过休息时间或营业时间,请重新选择");
        runBack(this);
      }
      // 重新赋值初始时间
      function runBack(that) {
        that.orderDetailEdit.endTime = "";
        let reserveDate = new Date(that.orderDetail.BeginTime * 1000);
        that.orderDetailEdit.beginTime = `${addZero(
          reserveDate.getHours()
        )}:${addZero(reserveDate.getMinutes())}`;
      }
    },
  },
};
</script>

<style>
</style>