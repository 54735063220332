import { request } from "../utils/axios"
// 获取当天工作人员、预约信息 param => date : 日期(2020-1-1)
export const getDailyData = (params) => {
    return request("get", "/rsr/uniapi/get_daily_data", params, false, true, true).then(res => res)
}
// 根据手机号码查找会员  param => phone
export const findMember = (params) => {
    return request("get", "/rsr/uniapi/find_member", params, false, true, true).then(res => res)
}
// 获取项目分类选择列表 param => 
export const getItemClassSelector = (params) => {
    return request("get", "/rsr/uniapi/get_item_class_selector", params, false, true, true).then(res => res)
}
// 获取项目选择列表 param => class_id 分类ID，不传或空时表示获取全部
export const getItemSelector = (params) => {
    return request("get", "/rsr/uniapi/get_item_selector", params, false, true, true).then(res => res)
}
// 获取员工选择列表 param => item_guid 项目Guid
export const getEmplSelector = (params) => {
    return request("get", "/rsr/uniapi/get_empl_selector", params, false, true, true).then(res => res)
}
// 获取时间选择列表 param => reserve_date 预约日期  empl_guid 员工guid
export const getTimeSelector = (params) => {
    return request("get", "/rsr/uniapi/get_time_selector", params, false, true, true).then(res => res)
}
// 修改 保存预约 param => {"OrderId":数据ID, 创建时为0 
// "ContactPhone":"手机号码",
// "ContactName":"姓名",
// "ContactSex":性别,  1 => 男 0 => 女
// "MemberGuid":"会员Guid"
// ,"ItemGuid":"项目Guid",
// "EmplGuid":"员工Guid",
// "ReserveDate":"预约日期（格式2021-05-26）",
// "BeginTime":"开始时间（格式21:00）",
// "EndTime":"结束时间（格式21:30）",
// "Remark":"备注"}
export const saveOrder = (params) => {
    return request("post", "/rsr/uniapi/save_order", params,true, true, true).then(res => res)
}

// 修改预约状态 order_id 数据id  status 状态 refuse_reason 当状态为拒绝时传入
export const changeOrderStatus = (params) => {
    return request("get", "/rsr/uniapi/change_order_status", params, false, true, true).then(res => res)
}