<template>
  <div class="index-page">
    <el-container>
      <el-header height="30px">
        <!-- 功能栏 -->
        <div class="flex justify-between function-box align-center">
          <!-- 显示待处理数量 -->
          <div v-if="orderNumber > 0">
            <span style="color: #409eff">{{ orderNumber }}</span>
            <span>个预约待回复</span>
          </div>
          <div v-else></div>
          <div class="df-row">
            <!-- 没有项目分类的时候 打开页面才会重新获取项目分类 -->
            <div class="df-col">
              <el-date-picker
                v-model="selectDate"
                @change="getDailyDataFun"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                size="mini"
              >
              </el-date-picker>
            </div>
            <div class="df-col">
              <el-button
                type="primary"
                class="btn-order"
                size="mini"
                @click="$refs.rdc.isShow = true"
              >
                预约
              </el-button>
            </div>
          </div>
        </div>
      </el-header>

      <el-main>
        <!-- 表格 -->
        <div class="table-box">
          <ReservationTable
            :emplData="emplData"
            :timeArrangementData="timeArrangementData"
            :orderData="orderData"
            :openOrderDetail="openOrderDetail"
            ref="rtb"
            :isLoading="isLoading"
          ></ReservationTable>
        </div>
      </el-main>
    </el-container>
    <!-- 预约弹窗 -->
    <ReservationCreateDialog ref="rdc"></ReservationCreateDialog>
    <!-- 处理弹窗 -->
    <ReservationDealDialog
      ref="rdd"
      :orderDetail="orderDetail"
      :orderDetailStatus="orderDetailStatus"
    ></ReservationDealDialog>
  </div>
</template>

<script>
// 组件
import ReservationTable from "./components/reservation-table.vue";
import ReservationDealDialog from "./components/reservation-deal-dialog.vue";
import ReservationCreateDialog from "./components/reservation-create-dialog.vue";
import { getDailyData } from "../../../api/reservation.js";
// import { getQueryVariable } from "../../utils/utils";
export default {
  components: {
    ReservationTable,
    ReservationCreateDialog,
    ReservationDealDialog,
  },
  data() {
    return {
      emplData: [], // 职员数据
      orderData: {}, // 所有预约数据
      orderDetail: {}, //单个预约订单详情数据
      timeArrangementData: [], //排班时间安排
      selectDate: "", //用户选择的时间
      orderDetailStatus: null, // 预约订单状态
      isLoading: false, // 页面是否显示刷新
      orderNumber: 0, // 待处理的预约数量
    };
  },
  created() {
    // console.log( getQueryVariable("token") );
    // 设置token
    //let token = this.$route.query.token;
    //this.$store.commit("setToken", token ? token : "");
  },
  mounted() {
    /* if (!this.$store.state.token) {
      this.$message("地址错误,请重新访问");
      return;
    } */
    //console.log(this.$store.state.toekn)
    //console.log(window.sessionStorage.getItem('token'))
    // 赋值当前日期
    let date = new Date();
    this.selectDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    // 获取工作人员、预约数据
    this.getDailyDataFun(this.selectDate);
    if (this.$store.state.toekn) {
      // 自动刷新表格数据
      this.refreshTable(30);
    }
  },
  methods: {
    // 刷新表数据 time表示秒
    refreshTable(time = 30) {
      let that = this;
      clearInterval();
      setInterval(() => {
        that.getDailyDataFun(that.selectDate);
      }, time * 1000);
    },
    // 用户选择日期事件 => 获取当天工作人员、预约信息
    getDailyDataFun(date) {
      let that = this;
      if (!date) {
        date = this.selectDate;
      }
      that.isLoading = true;
      getDailyData({ date })
        .then((res) => {
          // 状态为0 && 有员工数据
          that.isLoading = false;
          if (res.errcode == 0 && res.data.emplData.length > 0) {
            that.emplData = res.data.emplData; //res.data.emplData;
            that.orderData = res.data.orderData;
            that.timeArrangementData = res.data.emplData[0].ReserveTimes;
            if (that.orderData) {
              let n = 0;
              for (let key in that.orderData) {
                if (that.orderData[key].Status == 1) {
                  n++;
                }
              }
              that.orderNumber = n;
            }
          } else {
            that.$message(res.errcode);
          }
        })
        .catch((err) => {
          that.isLoading = false;
          that.$message("获取数据失败");
        });
    },

    // 打开处理预约弹窗
    openOrderDetail(row, column, cell, event) {
      // 如果没有预约安排
      // column.index - 1 代表的在这个用户ReserveTimes第几份
      let key = column.columnKey.split("key")[1];
      key = Number(key);
      let statusDataId = row.ReserveTimes[key].StatusDataId;
      if (statusDataId == 0) {
        return;
      }
      // 赋值单个预约订单详情
      this.orderDetail = this.orderData[statusDataId];
      // 赋值状态栏
      this.orderDetailStatus = this.orderDetail.Status;
      // 打开弹窗
      this.$refs.rdd.isEdit = false;
      this.$refs.rdd.isLoading = false;
      this.$refs.rdd.isShow = true;
    },
  },
};
</script>

<style lang="less" scoped>

.index-page {
  .el-header {
    margin: 0;
    padding: 0;
  }
  .el-main {
    padding: 20px 0 0;
  }
  .el-footer {
    padding-top: 20px;
  }
  .function-box {
    .btn-order {
      margin-left: 20px;
    }
  }
  // 预约时间
  .app-time {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
    line-height: 40px;
  }
  // 预约内容
  .app-content {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    text-align: left;
    padding: 0 20px;
  }
  // 预约状态
  .icon-status {
    width: 20px;
    height: 65px;
    position: absolute;
    top: 5px;
    right: 5px;
    text-align: center;
    line-height: 20px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #fff;
  }
  // 处理弹窗
  .el-dialog__header {
    border-bottom: 1px solid #dcdfe6;
  }
  .dialog-deal-box {
    min-width: 570px;
    .padding-left-sm {
      padding-left: 10px;
    }
    // 设置弹窗高度
    .el-dialog-div {
      // height: 70vh;
      overflow: hidden;
    }
    // dialog body padding调整
    .el-dialog__body {
      padding: 0 20px 20px 20px;
    }
    // 处理里面的ul li
    .dialog-deal-ul {
      list-style-type: none;
      li {
        border-top: 1px solid #dcdfe6;
        border-left: 1px solid #dcdfe6;
        border-right: 1px solid #dcdfe6;
        text-align: left;
        padding: 10px;
        &:last-of-type {
          border-bottom: 1px solid #dcdfe6;
        }
      }
    }
  }
  // 根据状态显示数据的样式
  .bg-them-1 {
    // 待回复
    color: #fff;
    background-color: #28a745 !important;
  }
  .bg-them-3 {
    // 确认
    color: #fff;
    background-color: #409eff;
  }
  .bg-them-4 {
    // 拒绝
    .icon-status {
      background-color: #e07f88;
    }
  }
  .bg-them-5 {
    // 完成
    .icon-status {
      background-color: #39c5bb;
    }
  }
  .bg-them-6 {
    // 删除
    .icon-status {
      background-color: #87888a;
    }
  }
  // 处理表格padding
  .el-table td {
    padding: 0;
  }
  .el-table .cell {
    padding: 0 !important;
  }
}
</style>
